import { tns } from "tiny-slider"

class testimonialsSlider extends HTMLElement {
  constructor(){
    super();

    this.sliderContainer = this.querySelector('#testimonial_slider');
    this.sliderContainerControls = this.querySelector('#testimonialsControls');
  }

  connectedCallback(){

    var slider = tns({
      container: this.sliderContainer,
      controlsContainer: this.sliderContainerControls,
      items: 1,
      slideBy: 1,
      autoplay: true,
      autoHeight: true,
      nav: false,
      autoplayButtonOutput: false
    });
  }
}
customElements.define('testimonials-slider', testimonialsSlider);